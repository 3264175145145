import React from "react";
import { CloserBranchStoresButton } from "../shared/components/CloserBranchStoresButton";

export const InitialSearchTop = ({ onCloserBranchstore }) => {
  return (
    <div className="c-branch-search__top">
      <h2 className="c-branch-search__heading">
        <span className="c-branch-search__heading-icon"></span>Buscador de locales
      </h2>
      {/* <CloserBranchStoresButton className="c-branch-search__location" onClick={onCloserBranchstore} /> */}
    </div>
  );
};
